.lightbox-gallery{
    margin-top: 30px;
    margin-bottom: 30px;
    //background: #eee;
    //padding: 15px;
    overflow: hidden;
    a{
        width: 33.3%;
        display: inline-block;
        //background: red;
        float: left;
        //margin-bottom: 5px;
        &.light-box-thumb-xs{
            width: 25%;
        }
        &.light-box-thumb-sm{
            width: 33.3%;
        }
        &.light-box-thumb-md{
            width: 50%;
        }
        &.light-box-thumb-lg{
            width: 100%;
        }
        .light-box-thumb{
            display: block;
            width: 100%;
            height: auto;
            position: relative;
            overflow: hidden;
            padding: 50.55% 0 0 0;
            /* 34.37% = 100 / (w / h) = 100 / (640 / 220) */
            background-repeat: no-repeat;
            background-position: center center fixed;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            border: 1px solid white;
            /*img{
                //padding: 5px;
                display: block;
                min-width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                //width: 100%;
                border: 1px solid white;
            }*/
        }
    }
}
.ekko-lightbox{
    .modal-header{
        display: none !important;
    }
    .modal-content .modal-body{
        padding: 5px;
        background: white;
    }
}