.carousel-item .page-header{
    //-webkit-clip-path: polygon(50% 0%, 100% 0, 100% 94%, 75% 100%, 25% 100%, 0 94%, 0 0);
    //clip-path: polygon(50% 0%, 100% 0, 100% 94%, 75% 100%, 25% 100%, 0 94%, 0 0);
    //clip-path: polygon(50% 5%, 0% 100%, 100% 100%);
    //-webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 94%, 75% 94%, 75% 100%, 56% 94%, 0 94%);
    //clip-path: polygon(0% 0%, 100% 0%, 100% 94%, 75% 94%, 75% 100%, 56% 94%, 0 94%);
    //-webkit-clip-path: inset(25% 0% round 0% 25%);
    //clip-path: ellipse(farthest-side closest-side at 25% 25%);
    //clip-path: circle(65% at 58% 50%);
    //-webkit-clip-path: url('#clipPathSVG');
}

.item .page-header .container{
    padding-top: 0;
}
.home-slider-next{
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 9;
    a{
        //border-radius: 45px;
        //background: white;
        color: white;
        font-size: 40px;
        line-height: 1;
        //line-height: 40px;
        //width: 40px;
        //height: 40px;
        text-align: center;
        display: inline-block;
        .fa{
            width: auto;
            line-height: 1;
        }
    }
}
.item .btn{
    margin-top: -20px;
    margin-bottom: 40px;
    color: black;
    font-weight: bold;
}
.slider-title.text-white{
    color: white;
    padding: 10px 0px;
    line-height: 1.3;
    display: inline-block;
}
.slider-title.text-black{
    color: black;
    padding: 10px 0px;
    line-height: 1.3;
    display: inline-block;
}
.text-left.slider-text,
.text-right.slider-text{
    .text{
        display: block;
    }
}
.slider-text{
    position: relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: translate3d(0,0,0);
    color: white;
    //font-style: italic;
    .text-sub{
        display: block;
        padding-bottom: 15px;
    }
    //font-weight: bold;
    .cc_title,
    .cc_text{
        line-height: 1.4;
        line-height: 1.2;
    }
    .cc_title{
        text-transform: uppercase;
        margin-bottom: 10px;
        &.text-xs{
            font-size: 24px;
            @media (max-width: 1199px) {
                font-size: 22px;
            }
            @media (max-width: 991px) {
                font-size: 20px;
            }
            @media (max-width: 767px) {
                font-size: 18px;
            }
        }
        &.text-sm{
            font-size: 28px;
            @media (max-width: 1199px) {
                font-size: 26px;
            }
            @media (max-width: 991px) {
                font-size: 24px;
            }
            @media (max-width: 767px) {
                font-size: 22px;
            }
        }
        &.text-md{
            font-size: 32px;
            @media (max-width: 1199px) {
                font-size: 28px;
            }
            @media (max-width: 991px) {
                font-size: 26px;
            }
            @media (max-width: 767px) {
                font-size: 24px;
            }
        }
        &.text-lg{
            font-size: 48px;
            @media (max-width: 1199px) {
                font-size: 40px;
            }
            @media (max-width: 991px) {
                font-size: 34px;
            }
            @media (max-width: 767px) {
                font-size: 28px;
            }
        }
        &.text-xl{
            font-size: 64px;
            @media (max-width: 1199px) {
                font-size: 52px;
            }
            @media (max-width: 991px) {
                font-size: 40px;
            }
            @media (max-width: 767px) {
                font-size: 32px;
            }
        }
    }
    .cc_text{
        font-weight: normal;
        &.text-xs{
            font-size: 14px;
            @media (max-width: 1199px) {
                font-size: 13px;
            }
            @media (max-width: 991px) {
                font-size: 12px;
            }
            @media (max-width: 767px) {
                font-size: 11px;
            }
        }
        &.text-sm{
            font-size: 16px;
            @media (max-width: 1199px) {
                font-size: 15px;
            }
            @media (max-width: 991px) {
                font-size: 14px;
            }
            @media (max-width: 767px) {
                font-size: 13px;
            }
        }
        &.text-md{
            font-size: 20px;
            @media (max-width: 1199px) {
                font-size: 19px;
            }
            @media (max-width: 991px) {
                font-size: 18px;
            }
            @media (max-width: 767px) {
                font-size: 17px;
            }
        }
        &.text-lg{
            font-size: 26px;
            @media (max-width: 1199px) {
                font-size: 23px;
            }
            @media (max-width: 991px) {
                font-size: 21px;
            }
            @media (max-width: 767px) {
                font-size: 19px;
            }
        }
        &.text-xl{
            font-size: 32px;
            @media (max-width: 1199px) {
                font-size: 28px;
            }
            @media (max-width: 991px) {
                font-size: 24px;
            }
            @media (max-width: 767px) {
                font-size: 19px;
            }
        }
    }
    .text{
        //text-transform: uppercase;
        line-height: 1.4;
        line-height: 1.2;
        //font-weight: bold;
        //line-height: 1.7em;
        display: inline-block;
        //margin: 0 17px;
        //font-family: $title-font;
        //padding: 8px 16px;
        //font-family: 'Abside';
        &.text-xs{
            font-size: 14px;
            @media (max-width: 1199px) {
                font-size: 13px;
            }
            @media (max-width: 991px) {
                font-size: 12px;
            }
            @media (max-width: 767px) {
                font-size: 11px;
            }
        }
        &.text-sm{
            font-size: 24px;
            @media (max-width: 1199px) {
                font-size: 22px;
            }
            @media (max-width: 991px) {
                font-size: 20px;
            }
            @media (max-width: 767px) {
                font-size: 18px;
            }
        }
        &.text-md{
            font-size: 32px;
            @media (max-width: 1199px) {
                font-size: 27px;
            }
            @media (max-width: 991px) {
                font-size: 22px;
            }
            @media (max-width: 767px) {
                font-size: 16px;
            }
        }
        &.text-lg{
            font-size: 48px;
            @media (max-width: 1199px) {
                font-size: 40px;
            }
            @media (max-width: 991px) {
                font-size: 32px;
            }
            @media (max-width: 767px) {
                font-size: 24px;
            }
        }
        &.text-xl{
            font-size: 64px;
            @media (max-width: 1199px) {
                font-size: 52px;
            }
            @media (max-width: 991px) {
                font-size: 40px;
            }
            @media (max-width: 767px) {
                font-size: 32px;
            }
        }
    }
}
.carousel-text-position-top,
.carousel-text-position-middle,
.carousel-text-position-bottom{
    position: absolute;
    left: 0;
    right: 0;
}
.carousel-text-position-top{
    top: 100px;
}
.carousel-text-position-middle{
    top: 52%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 10000000;
    @media (max-width: 767px) {}
}
.carousel-text-position-bottom{
    bottom: 100px;
}
.slider-with-intro-arrow .carousel-text-position-bottom{
    bottom: 55px;
}
.carousel-indicators{
    bottom: 20px;
}
.page-carousel{
    background: $slider-bg;
    border-radius: 0 !important;
}
.page-header{
    //border-bottom: 5px solid $bg-info;
    //border-top: 5px solid $slider-bg;
}
.page-header.page-header-fs{
    min-height: 100vh;
    min-height: calc(100vh - 83px);
    height: auto;
    @media (max-width: 767px) {
        min-height: 60vh;
    }
}
.page-header.page-header-hs {
  min-height: 45vh;
  height: auto;
}
.page-header.page-header-25 {
  min-height: 30vh;
  height: auto;
}
.page-header.page-header-75 {
  min-height: 70vh;
  height: auto;
}
.page-header.page-header-small{
    min-height: 45vh !important;
    max-height: 700px;
}
.page-header.page-header-home{
    min-height: 55vh !important;
    max-height: 700px;
}