.site{
    position: relative;
    z-index: 0;
    padding-top: 75px;
    .gmap{
        width: 100%;
        height: 350px;
        overflow: hidden;
        border-radius: 12px;
        //margin-bottom: 20px;
        border: 1px solid #eee;
    }
    &.transparent{
        background: transparent;
    }
    .container{
        //max-width: 970px;
    }
    .hidden{
        display: none;
    }
    $call-us-color: $bg-info;
    $call-us-color: $info-color;
    .carousel-control.carousel-control-prev,
    .carousel-control.carousel-control-next{
        background: darken($call-us-color,0%);
        border: 0px solid lighten($call-us-color,5%);
        border-radius: 4px;
        padding: 20px;
    }
    .call-us{
        position: relative;
        .row{
            height: 0;
            .col-12{
                position: relative;
                height: 0;
                a{
                    position: absolute;
                    display: inline-block;
                    top: 5px;
                    right: 15px;
                    background: darken($call-us-color,0%);
                    border: 0px solid lighten($call-us-color,5%);
                    color: white;
                    padding: 10px 16px;
                    z-index: 1020;
                    border-radius: 4px;
                    line-height: 1;
                    box-shadow: 1px 1px 10px 3px rgba(0,0,0,.1);
                    transition: all 0.3s ease-out;
                    font-weight: bold;
                    span{
                        display: block;
                        padding: 2px 0px;
                        line-height: 1.1;
                        text-transform: uppercase;
                    }
                }
                a:hover{

                    background: darken($call-us-color,2%);
                    border: 0px solid lighten($call-us-color,0%);
                }
            }
        }
    }
    &.hasSlider{
        .call-us{
            .row{
                .col-12{
                    a{
                        position: absolute;
                        display: inline-block;
                        top: -45px;
                    }
                }
            }
        }
    }
    .breadcrumb{
        background: rgba(0,0,0,0);
    }
    .wrapper{
        background: transparent;
    }
    .card-description{
        padding-top: 10px;
        a{
            color: $info-color;
            text-decoration: underline;
        }
        ul,ol{
            padding: 10px 0 20px;
            li{
                display: block;
                list-style: none;
                padding-top: 2px;
                padding-bottom: 2px;
            }
            li {
                padding-left: 1em;
            }
            li:before {
                content: "\f0da"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                margin-left: -1em; /* same as padding-left set on li */
                width: 1em; /* same as padding-left set on li */
            }
        }
    }
    .navbar-brand{
        padding: 0;
        position: relative;
        height: 60px;
        .logo{
            top: 0;
            position: absolute;
            max-height: 60px;
            @media (max-width: 575px) {
                left: 15px;
            }
        }
    }
    .navbar-toggler{
        @media (max-width: 575px) {
            position: relative;
            right: 15px;
        }
    }
    .logo_kleur{
        display: block;
    }
    .logo-wit{
        display: none;
    }
    .navbar-transparent{
        .logo_kleur{
            display: none;
        }
        .logo-wit{
            display: block;
        }
    }
    .header-wrapper{
        height: 550px;
    }
    .section{
        padding-top: 50px;
        background-color: rgba(0,0,0,0);
    }
    .section-home{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .section-world-map{
        background-image: url('/assets/img/bg.png');
        background-position: top center;
        background-repeat: no-repeat;
        //border-top: 5px solid $slider-bg;
    }
    .navbar{
        //box-shadow: none;
        background: rgba(255,255,255,.98);
    }
    .navbar .navbar-nav .nav-item .nav-link{
        opacity: 1;
    }
    .navbar .navbar-nav .nav-item .nav-link{
        color: $info-color;
    }
    .navbar.navbar-transparent .navbar-nav .nav-item .nav-link{
        color: white;
    }
    .inverse-nav{
        .nav-item .nav-link,
        .nav-item .nav-link:hover,
        .nav-item .nav-link:focus,
        .nav-item .nav-link:active{
            background: rgba(255,255,255,.2);
            border-radius: 4px;
        }
    }
    .navbar-main{
        margin-right: 10px;
    }
    .navbar-transparent .navbar-brand, [class*="bg"] .navbar-brand {
        color: #FFFFFF;
        opacity: 1;
        filter: alpha(opacity=100);
    }
    .navbar .navbar-nav .nav-item .nav-link [class^="fa"] {
        font-size: 17px;
        position: relative;
        margin: 0px;
        right: 0px;
    }
    .microdata{
        color: #66615b;
        color: $info-color;
        text-transform: uppercase;
        font-family: "Montserrat", "Helvetica", Arial, sans-serif;
        p{
            font-size: .9em;
            line-height: 1.4;
            opacity: .9;
            font-weight: 400;
        }
        .microCompanyName{
            display: none;
        }
        .microAddress{
            padding-top: 10px;
        }
        a{
            color: $info-color;
            text-decoration: underline;
        }
    }
    @media (max-width: 991px){
    .collapse.navbar-collapse{
        background-color: $bg-info;
        border-left: 1px solid white;
        ul{
            height: auto;
        }
        .navbar-nav.inverse-nav{
            margin-left: auto !important;
        }
        .navbar-nav .nav-item .nav-link [class^="fa"]{
            font-size: 17px;
            position: relative;
            margin: 0px;
            right: 0px;
        }
    }
    .collapse.navbar-collapse::after{
        background-color: $bg-info;
        border-left: 1px solid white;
    }
    .collapse.navbar-collapse .navbar-nav > .nav-item > .nav-link {
        //margin: 0px 0px;
        color: white !important;
        //text-transform: uppercase;
        //font-weight: 600;
        //font-size: 12px;
        //line-height: 1.5em;
        //padding: 15px 0;
    }
    }
    .navbar{
    .navbar-nav{
        .single.nav-item.nav-alarm{
            a.nav-link{
                color: #ab0f0d;
                padding: 4px 5px;
            }
        }
    }
    }

    h3.title-uppercase{
       margin-top: 50px;
    }
    p{
        margin-top: 0px;
    }
    .creators{
        margin-top: 100px;
    }
    .more-info{
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .section-with-breadcrumbs{
        padding-top: 5px;
    }
    .breadcrumb{
        margin-bottom: 0px;
    }
    .zoom-parent{
        display: block;
        position: relative;
        padding: 59.25% 0 0 0;
        //margin-top: 20px;
        //margin-bottom: 20px;
        overflow: hidden;
        //border-radius: 12px;
        //-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        .zoom-child{
            //border-radius: 12px;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: #ccc;
            background-repeat: no-repeat;
            background-position: center center fixed;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            transition: all .9s;
        }
        &:hover .zoom-child,
        &:focus .zoom-child {
            border-radius: 12px;
            transform: scale(1.1);
        }
    }
}
@mixin theme-mixing($color) {
    .list-group-item.active {
        background-color: lighten($color,10%);
        border-color: lighten($color,10%);
    }
    .dropdown-item{
        &.active{
            background-color: lighten($color,7%);
            color: white;
        }
    }
    .dropdown-item:hover{
        background-color: lighten($color,7%) !important;
        color: white !important;
    }
    .pagination > li > a,
    .pagination > li > span,
    .pagination > li:first-child > a,
    .pagination > li:first-child > span,
    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
      border: 2px solid $color;
      color: $color;
    }
    .pagination > li > a:hover,
    .pagination > li > a:focus,
    .pagination > li > a:active,
    .pagination > li.active > a,
    .pagination > li.active > span,
    .pagination > li.active > a:hover,
    .pagination > li.active > span:hover,
    .pagination > li.active > a:focus,
    .pagination > li.active > span:focus {
      background-color: $color;
      border-color: $color;
      color: #FFFFFF;
    }
    .section.section-page-color{
        background-color: lighten($brown-color, 7%);
        color: white;
        h3{
            margin-top: 20px;
        }
    }
}
body{
    &.color-default{
        @include theme-mixing($default-color);
    }
    &.color-primary{
        @include theme-mixing($primary-color);
    }
    &.color-info{
        @include theme-mixing($info-color);
    }
    &.color-success{
        @include theme-mixing($success-color);
    }
    &.color-warning{
        @include theme-mixing($warning-color);
    }
    &.color-danger{
        @include theme-mixing($danger-color);
    }
}
body{
    color: black;
    b, strong{
        //font-weight: 300;
    }
    .section-grey{
        background: #eee;
    }
    .section-white{
        background: white;
    }
    h1.title,
    h2.title,
    h3.title{
        font-size: 2.825em;
        line-height: 1.4;
        margin: 15px 0 15px;
        color: $info-color;
        //color: #ab710d;
        //margin-top: 50px;
        @media (max-width: 575px){
            font-size: 1.825em;
        }
        @media (max-width: 767px){
            font-size: 2.325em;
        }
        @media (max-width: 991px){
            font-size: 2.825em;
        }
    }
    h1.title{
        //color: #ab0f0d;
    }
    h1.subtitle,
    h2.subtitle,
    h3.subtitle{
        font-size: 1.425em;
        line-height: 1.4;
        margin: 20px 0 20px;
    }
    .title-row{
        h1.title,
        h2.title,
        h3.title{
            margin-bottom: 20px;
        }
    }
    .title-mb30,
    .title-news{
        margin-bottom: 30px !important;
    }
    .home-intro{
        padding-top: 15px;
        padding-bottom: 30px;
        margin-top: -40px;
        h1{
            margin-top: 0;
        }
        p{
            font-size: 17px;
        }
    }
    .list-group-item.active {
        z-index: 2;
        color: #fff;
        //background-color: #f5593d;
        //border-color: #f5593d;
    }
    .image-box{
        background-repeat: no-repeat;
        background-position: center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        padding-top: 66.25%;
        position: relative;
        border-radius: 4px;
        margin-bottom: 0px;
        display: block;
        span{
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            color: white;
            margin: 0;
            background: rgba($bg-info,.2);
            transition: all 0.2s ease;
            .fa{
                position: absolute;
                top: 45%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);

                left: 50%;
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translateX(-50%);

                font-size: 32px;
                line-height: 32px;
                width: 32px;
                height: 32px;
                font-weight: normal;
                opacity: .7;
                transition: all 0.2s ease;
            }
        }
        &:hover{
            span{
               background: rgba($bg-info,.3);
               .fa{
                    font-size: 36px;
                    width: 36px;
                    height: 36px;
                }
            }
        }
    }
    .card{
        .card-description{
            margin-top: 0;
            .btn{
                margin-top: 20px;
            }
        }
    }
    .intro{
        margin-bottom: 15px;
        p{
            font-size: 17px;
        }
        ul,ol{
            padding: 10px 0 10px;
            margin-bottom: 0;
            li{
                display: block;
                list-style: none;
                padding-top: 2px;
                padding-bottom: 2px;
                font-size: 17px;
            }
            li {
                padding-left: 1em;
            }
            li:before {
                content: "\f0da"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                margin-left: -1em; /* same as padding-left set on li */
                width: 1em; /* same as padding-left set on li */
            }
        }
    }
    .title-row.text-main{
        .intro{
            margin-bottom: 30px;
        }
    }
    .text-intro{
        p{
            @media (min-width: 576px){
                margin-top: 0;
            }
            font-weight: bold;
        }
    }
    .text-main{
        img{
            padding: 5px;
            background: white;
            box-shadow: 0 3px 3px rgba(0,0,0,.2);
        }
        p{
            font-size: 15px;
            font-weight: 100;
            margin-bottom: 15px;
        }
        h2.title{
            font-size: 2.525em;
            margin-bottom: 20px;
        }
        ul,ol{
            padding: 0px 0 20px;
            li{
                display: block;
                list-style: none;
                padding-top: 2px;
                padding-bottom: 2px;
                font-size: 15px;
                font-weight: 100;
            }
            li {
                padding-left: 1.2em;
            }
            li:before {
                content: "\f0da"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                margin-left: -1.2em; /* same as padding-left set on li */
                width: 1em; /* same as padding-left set on li */
            }
        }
        .intro{
            p,
            ul li,
            ol li{
                font-size: 17px;
                font-weight: 400;
            }
        }
    }
    .module-nav{
        padding-top: 25px;
        .mod-nav-item{
            text-transform: uppercase;
            border: 1px solid $info-color;
            background: white;
            color: $info-color;
            padding: 8px 12px;
            line-height: 1;
            border-radius: 3px;
            display: inline-block;
            font-size: 11px;
            font-weight: bold;
        }
        .prev-item{
            float: left;
        }
        .next-item{
            float: right;
        }
    }
    .our-fleet{
        //margin-top: 30px;
        margin-bottom: -30px;
        padding: 30px;
        background: rgba($info-color, .1);
        border-radius: 4px;
    }
    .services{
        //padding: 20px;
        //border-radius: 4px;
        //border: 1px solid $info-color;
        background: rgba($info-color, .1);
        padding-top: 30px;
        padding-bottom: 30px;
        h2.title{
            margin-top: 0px;
            //color: $info-color;
        }
        h3.title{
            font-size: 25px;
            //margin-top: 0px;
            color: $info-color;
        }
        ul{
            padding-bottom: 0;
        }
    }
    .service-list-item{
        margin-bottom: 15px;
    }
    .info-box{
        //padding: 10px;
        //border: 1px solid $bg-info;
        //background: rgba(0,0,0,.1);
        padding-top: 9px;
        h2{
            font-size: 21px;
            font-weight: bold;
            margin: 0;
            padding: 0;
        }
        h3{
            font-size: 21px;
            font-weight: bold;
            margin: 0;
            padding: 0;
            color: $info-color;
        }
        p{
            font-size: 17px;
        }
        &.info-box-link{
            padding: 15px;
            h2{
                margin: 10px 0 20px;
            }
        }
    }
    .contact{
        position: relative;
        padding-left: 100px;
        margin-top: 15px;
        img{
            position: absolute;
            left: 0;
            top: 0;
            display: inline-block;
            border-radius: 45px;
            width: 90px;
        }
        p{
            font-weight: bold;
            line-height: 1.3;
            //font-size: 17px;
            a,
            span{
                font-weight: normal;
                font-size: 16px;
            }
        }
        a:visited,
        a{
            color: $info-color;
            text-decoration: none;
            i{
                width: 20px;
                display: inline-block;
            }
        }
    }
    .footer{
        border-top: 1px solid $bg-info;
        border-top: 0px solid #eee;
        background: $bg-info;
        background: rgba($bg-info, .1);
        //color: white;
        &.footer-big{
            border: none !important;
        }
        .footer-container{
            //background: rgba($info-color, .1);
        }
        hr{
            border-color: #eee;
        }
    }
    #footer_nav1,
    #footer_nav2{
        .nav-link{
            padding: 0;
            color: $info-color;
        }
        .nav-link:hover{
            color: lighten($info-color,20%);
        }
        .active{
            .nav-link{
                color: lighten($info-color,20%);
            }
        }
    }
    .footer-brand{
        img{
            display: block;
            width: 100%;
            max-width: 120px;
        }
    }
    .img-responsive{
        max-width: 100%;
    }
    .footer-logo{
        display: block;
        height: 60px;
    }
    .home-contact{
        .image-landscape{
            display: block;
            position: relative;
            padding: 75% 0 0 0;
            //margin-top: 20px;
            //margin-bottom: 20px;
            overflow: hidden;
            margin-top: 10px;
        }
        h2.title{
            margin-top: 0;
            padding-top: 0;
        }
    }
    .pagination_wrapper{
        margin: 15px 0;
    }
    .card:not(.card-plain),
    .card{
        -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
        -webkit-transition: -webkit-transform 300ms cubic-bezier(0.34, 2, 0.6, 1), -webkit-box-shadow 200ms ease;
        transition: -webkit-transform 300ms cubic-bezier(0.34, 2, 0.6, 1), -webkit-box-shadow 200ms ease;
        transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
        transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease, -webkit-transform 300ms cubic-bezier(0.34, 2, 0.6, 1), -webkit-box-shadow 200ms ease;
        &:hover {
            -webkit-box-shadow: 0px 12px 19px -7px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 12px 19px -7px rgba(0, 0, 0, 0.2);
            transform: translateY(-2px);
            -webkit-transform: translateY(-2px);
            -ms-transform: translateY(-2px);
            -moz-transform: translateY(-2px);
        }
        .card-footer{
            padding-top: 0;
        }
        .card-address{
            padding-bottom: 15px;
        }
        .card-contacts{
            color: $info-color;
            i{
                width: 24px;
                text-align: left;
                display: inline-block;
            }
        }
    }
}
/*

    SWERK NOTIFICATIONS

*/
.swerk-notifcatie{
    position: fixed;
    z-index: 999999999999;
    top: -100px;
    left: 0;
    right: 0;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    margin-bottom: 0;
}
.swerk-notifcatie .alert{
    min-height: 81px;
    /*max-height: 100px;*/
    border: 0;
    border-radius: 0;
    color: white;
    overflow: hidden;
    box-shadow: 0;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
}
.swerk-notifcatie .alert p{
    float: left;
    font-size: 14px;
    line-height: 52px;
    margin-top: 0;
}
.swerk-not-title{
    display: inline-block;
    margin-right: 6px;
    line-height: 52px;
    height: 22px;
    font-weight: bold;
    position: relative;
    padding-left: 25px;
}
.swerk-notifcatie .alert i{
    font-size: 20px;
    line-height: 52px;
    position: absolute;
    left: 0;
    top: -1px;
}
.swerk-notifcatie .alert-success{
    background: #27c24c;
}
.swerk-notifcatie .alert-info{
    background: #23b7e5;
}
.swerk-notifcatie .alert-danger{
    background: #f05050;
}
.swerk-notifcatie .alert-warning{
    background: #fad733;
}
.swerk-notifcatie .alert .close{
    color: white;
    opacity: 1;
    position: absolute;
    right: 15px;
    top: 15px;
}
.swerk-notifcatie .alert .close i{
    font-size: 18px;
}
#lang-nav{
    .dropdown-item{
        text-transform: capitalize !important;
    }
}