// Fonts
//@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Roboto+Slab:400,700|Material+Icons);


// Variables brand
//@import "site/variables";
//@import "site/mixins";

// Site bootstrap variables override
//@import "bootstrap.variables.site";

// Bootstrap
//@import "../libs/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../css/bootstrap.min";

//@import "../css/fonts";

// Material
@import "paper-kit";

// Fonts
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,300,700);
@import "../libs/font-awesome-sass/font-awesome";
@import "../css/nucleo-icons";
//@import "../css/Abside-Regular";

// Plugins
@import "../libs/lightbox-master/dist/ekko-lightbox";
//@import "hover/hover";
.form-control.error{
    border-color: $danger-color;
    background-color: rgba($danger-color,.1);
}
label.error{
    margin-bottom: 0;
    font-size: 12px;
    color: $danger-color;
}
.form-control.form-control-radio{
    padding-top: 11px;
    padding-left: 0;
    border: 0;
    background: none;
}
$slider-bg: white;
#curveUpColor path,
#curveDownColor path{
	fill: darken(#eee, 0%);
	stroke: darken(#eee, 0%);
	fill: rgba($bg-info, .1);
    stroke: rgba($bg-info, .1);
    stroke: none;
}
.svgWrap40{
    height: 40px;
}
.svgWrap50{
    height: 50px;
}
.card.card:not(.card-plain) .card-image .img {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.carousel-curve{
    //position: absolute;
    //bottom: 0;
    //left: 0;
    //right: 0;
    //display: block;
    //z-index: 3;
}
.call-us-divider{
    position: absolute;
    left: 0;
    right: 0;
    top: -50px;
    z-index: 3;
}
.curve-blue path{
    fill: darken($bg-info, 0%);
	stroke: darken($bg-info, 0%);
	stroke: none;
}
.curve-trans-blue{
    height: 40px;
    path{
        fill: rgba($bg-info, .1);
        stroke: rgba($bg-info, .1);
        stroke: none;
    }
}
.curve-trans-bluelight{
    height: 40px;
    path{
        fill: rgba($bg-info, .05);
        stroke: rgba($bg-info, .05);
        stroke: none;
    }
}
.footer-region,
.bg-trans-bluelight{
    background: rgba($bg-info, .05);
}
.curve-white path{
    fill: white;
    stroke: white;
}
.footer-curve{
    position: relative;
    height: 50px;
}
// Generic
@import "site/site";
//@import "site/header";
//@import "site/generic";
//@import "site/global";
//@import "site/helpers";
//@import "site/footer";

// Groupmapping components
@import "site/components/slider";
@import "site/components/parallax";
//@import "site/components/page-header-detail";
//@import "site/components/cards";
@import "site/components/lightbox";

.img-bg{
    background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	background-attachment: inherit;
	&.img-round{
    	border-radius: 90px;
	}
	&.img64{
    	width: 64px;
    	height: 64px;
	}
	&.img128{
    	width: 128px;
    	height: 128px;
	}
	&.img256{
    	width: 256px;
    	height: 256px;
	}
}
body{
    .card{
        &.card-no-hover{
            &:hover{
                transform: translateY(0);
                -webkit-transform: translateY(0);
                -ms-transform: translateY(0);
                -moz-transform: translateY(0);
            }
        }
    }
}
.accordion{
    .card{
        .card-header{
            background-color: #F3F3F5;
            h2{
                .btn.btn-link{
                    display: block;
                    width: 100%;
                    text-align: left;
                    color: #1176da;
                    &:after{
                        font-family: 'FontAwesome';
                        content: '\f067';
                        float: right;
                    }
                    &.collapsed{
                        color: black;
                        &:after{
                            font-family: 'FontAwesome';
                            content: '\f068';
                        }
                    }
                }
            }
        }
        .card-body{
            padding: 0 15px;
            .member{
                padding: 15px 10px;
                background: white;
                &:hover{
                    background: rgba(#1176da,.1);
                }
            }
        }
    }
}
// Groupmapping page types
//@import "site/page-types/team";
